(function(){
    if (typeof bookolo === "object" && !document.getElementById("bookolo-engine")) {
        if (typeof document.documentElement.classList !== "undefined") {

            let bookoloDomain = "https://www.bookoloengine.com/";

            if (typeof CSS === "undefined") {
                document.documentElement.classList.add("no-pointer-events");
            } else if (CSS.supports("pointer-events", "none") === false) {
                document.documentElement.classList.add("no-pointer-events");
            }

            function bookolo_exitDetect(callback) {
                setTimeout(function () {
                    let out;
                    document.addEventListener("mouseout", function (e) {
                        if (e.clientY < 32 && out !== 1) {
                            callback();
                            out = 1;
                        }
                    });
                }, 5000);
            }

            function bookolo_poAppend(callback) {
                if (false && localStorage.getItem('bookoloPrivateOffers')) {
                    let data = JSON.parse(localStorage.getItem('bookoloPrivateOffers'));
                    bookolo_addHtml(data);
                    callback();
                } else {
                    let request = new XMLHttpRequest();

                    var url = bookoloDomain + "v3/book/hotel-" + bookolo.hotel +
                        '?lang=' + (bookolo.language ? bookolo.language : '') +
                        '&currency=' + (bookolo.currency ? bookolo.currency  : '') +
                        '&do=specialOffer';

                    request.open('GET', url, true);

                    request.onload = function () {
                        if (request.status === 200) {
                            let data = JSON.parse(request.responseText);
                            localStorage.setItem('bookoloPrivateOffers', request.responseText);
                            if (!document.querySelector('.bookolo--private-offer-dialog') || !document.querySelector('.bookolo--private-offer-side') || !document.querySelector('.bookolo--private-offer-small')) {
                                bookolo_addHtml(data);
                                callback();
                            }
                        }
                    };

                    request.send();
                }
            }

            function bookolo_addHtml(data) {
                if (typeof data["private-offer-dialog"] !== "undefined") {
                    document.body.insertAdjacentHTML('beforeend', data["private-offer-dialog"]);
                }

                if (typeof data["private-offer-side"] !== "undefined") {
                    document.body.insertAdjacentHTML('beforeend', data["private-offer-side"]);
                }

                if (typeof data["private-offer-small"] !== "undefined") {
                    document.body.insertAdjacentHTML('beforeend', data["private-offer-small"]);
                }
            }

            function domReady(callback) {
                document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
            }

            domReady(function () {
                var link = document.createElement("link");
                link.href = bookoloDomain + "v3/book/dist/css/bookolo-offers.min.css?2bcc5ec8f8";
                link.type = "text/css";
                link.rel = "stylesheet";
                document.getElementsByTagName("head")[0].appendChild(link);

                setTimeout(function () {
                    bookolo_poAppend(function () {
                        (function bookolo_po_side(selector) {
                            if (selector !== null) {
                                if (selector.classList.contains('bookolo--auto-open-false')) {
                                    localStorage.setItem("bookoloPrivateOffersSide", "1");
                                }

                                if (!selector.classList.contains('bookolo--state-active') && !localStorage.getItem("bookoloPrivateOffersSide")) {
                                    setTimeout(function(){
                                        selector.classList.add("bookolo--state-active");
                                    },1000);
                                }

                                document.querySelector("[data-bookolo-po-side--open]").addEventListener("click", function () {
                                    if (!selector.classList.contains('bookolo--state-active')) {
                                        selector.classList.add("bookolo--state-active");
                                    }
                                });

                                document.querySelector("[data-bookolo-po-side--close]").addEventListener("click", function () {
                                    if (selector.classList.contains('bookolo--state-active')) {
                                        selector.classList.remove("bookolo--state-active");

                                        if (localStorage.getItem('bookoloPrivateOffersSide') === null) {
                                            localStorage.setItem("bookoloPrivateOffersSide", "1");
                                        }
                                    }
                                });
                            }

                        })(document.querySelector(".bookolo--private-offer-side"));

                        (function bookolo_po_dialog(selector) {
                            if (selector !== null) {
                                if (document.cookie.indexOf('bookolo-po-dialog=1') === -1) {
                                    bookolo_exitDetect(function () {
                                        selector.classList.add("bookolo--state-active");
                                    });
                                }

                                document.querySelector("[data-bookolo-po-dialog--close]").addEventListener("click", function () {
                                    if (selector.classList.contains('bookolo--state-active')) {
                                        selector.classList.remove("bookolo--state-active");

                                        if (localStorage.getItem('bookoloPrivateOffersDialog') === null) {
                                            localStorage.setItem("bookoloPrivateOffersDialog", "1");
                                        } else {
                                            localStorage.setItem("bookoloPrivateOffersDialog", parseInt(localStorage.getItem('bookoloPrivateOffersDialog')) + 1);
                                        }

                                        if (parseInt(localStorage.getItem("bookoloPrivateOffersDialog")) > 1) {
                                            document.cookie = "bookolo-po-dialog=1; path=/; max-age=604800";
                                        }
                                    }
                                });

                                selector.addEventListener("click", function (e) {
                                    if (e.target.classList.contains('bookolo--private-offer-dialog')) {
                                        if (selector.classList.contains('bookolo--state-active')) {
                                            selector.classList.remove("bookolo--state-active");

                                            if (localStorage.getItem('bookoloPrivateOffersDialog') === null) {
                                                localStorage.setItem("bookoloPrivateOffersDialog", "1");
                                            } else {
                                                localStorage.setItem("bookoloPrivateOffersDialog", parseInt(localStorage.getItem('bookoloPrivateOffersDialog')) + 1);
                                            }

                                            if (parseInt(localStorage.getItem("bookoloPrivateOffersDialog")) > 1) {
                                                document.cookie = "bookolo-po-dialog=1; path=/; max-age=604800";
                                            }
                                        }
                                    }
                                });
                            }
                        })(document.querySelector(".bookolo--private-offer-dialog"));

                        (function bookolo_po_small(selector) {
                            if (selector !== null) {
                                document.querySelector("[data-bookolo-po-small--toggle]").addEventListener("click", function () {
                                    if (!selector.classList.contains('bookolo--state-active')) {
                                        selector.classList.add("bookolo--state-active");
                                    } else {
                                        selector.classList.remove("bookolo--state-active");
                                    }
                                });
                            }
                        })(document.querySelector(".bookolo--private-offer-small"));
                    });
                }, 500);
            });
        }
    }
})();
